



































































































































































































import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";

@Component({
  directives: {
    ClickOutside,
  },
  components: {
    ModalDotCreate: () => import("@/components/dots/modals/create.vue"),
    ModalHunchCreate: () => import("@/components/hunches/modals/create.vue"),
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class LayoutUser extends Vue {
  @Getter("auth/me") account!: Account;

  @Getter("workspace/viewing") workspace!: Workspace;

  showProfile = false;

  showAdd = false;

  showDotCreate = false;

  showHunchCreate = false;

  search = "";

  handleShowCreateDot() {
    this.showDotCreate = true;
    this.showAdd = false;
  }

  handleShowCreateHunch() {
    this.showHunchCreate = true;
    this.showAdd = false;
  }

  handleSearch() {
    this.$router.push({ name: "hunches", query: { search: this.search } });
    this.search = "";
  }

  private goToAdmin() {
    this.showProfile = false;
    window.location.href = `${process.env.VUE_APP_WEB_URL}/admin/workspaces`;
  }

  private get viewport() {
    return window.innerWidth;
  }
}
